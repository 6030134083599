<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-col cols="12">
          <DatePicker
            v-model="date"
            prepend-inner-icon="mdi-calendar"
            outlined
            label="日付"
            :disabled="edit"
            :rules="[Rules.Required]"
        /></v-col>
        <v-col cols="12" class="dialog-padding">
          <v-autocomplete
            v-model="user"
            :items="users"
            outlined
            :disabled="edit"
            :rules="[Rules.Required]"
            label="氏名"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
          <v-autocomplete
            v-model="shift"
            :items="shifts"
            outlined
            label="シフト"
            @input="shiftSelect"
            item-text="name"
            item-value="code"
            chips
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.start"
            label="勤務開始"
            prepend-inner-icon="mdi-clock-start"
            outlined
            :rules="[Rules.Required]"
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.end"
            label="勤務終了"
            prepend-inner-icon="mdi-clock-end"
            outlined
            :rules="[Rules.Required(data.end, true)]"
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.breaktime"
            label="休憩"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.work"
            label="実働"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.over"
            label="超過"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.inover"
            label="時間内残業"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="data.midnight"
            label="深夜"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <v-text-field
            v-model.number="data.distance"
            label="移動距離"
            suffix="km"
            type="number"
            step="0.1"
            prepend-inner-icon="mdi-map-marker-distance"
            outlined
          ></v-text-field>
        </v-col>
        <v-col class="dialog-padding">
          <v-text-field
            v-model.number="data.amount"
            label="単価"
            suffix="円"
            type="number"
            step="1"
            prepend-inner-icon="mdi-wallet"
            outlined
            v-if="data.amount != null"
          ></v-text-field>
        </v-col>
        <v-col></v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-radio-group
            v-model="selectWork"
            row
            label="出勤種別:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="通常" :value="SelectsWork.Default"></v-radio>
            <v-radio label="休日" :value="SelectsWork.Break"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="8" class="dialog-padding">
          <v-radio-group
            v-model="selectLateLeave"
            row
            label="遅刻早退:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsLateLeave.Default"></v-radio>
            <v-radio label="遅刻" :value="SelectsLateLeave.Late"></v-radio>
            <v-radio label="早退" :value="SelectsLateLeave.Leave"></v-radio>
            <v-radio
              label="遅刻早退"
              :value="SelectsLateLeave.LateLeave"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-radio-group
            v-model="selectDayCount"
            row
            label="出勤日数:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="1" :value="SelectsDayCount.Default"></v-radio>
            <v-radio label="2" :value="SelectsDayCount.Over"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="dialog-padding">
          <v-radio-group
            v-model="selectNightCount"
            row
            label="夜勤日数:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="0" :value="SelectsNightCount.Default"></v-radio>
            <v-radio label="1" :value="SelectsNightCount.Over"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="dialog-padding">
          <v-radio-group
            v-model="selectMeal"
            row
            label="食事:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsMeal.Default"></v-radio>
            <v-radio label="あり" :value="SelectsMeal.Eat"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="dialog-padding">
          <v-radio-group
            v-model="selectAbsence"
            row
            label="欠勤:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsAbsence.Default"></v-radio>
            <v-radio label="あり" :value="SelectsAbsence.Absence"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <p>有給設定時は実績時間に対象となる時間を設定してください。</p>
      </v-row>
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import AttendanceFix from "@/models/AttendanceFix";
import { COMMON_SHIFT_PATTERNS_REF as CommonShiftRefCode } from "@/defines";

const Users = [{ name: "", code: "" }];
const Shifts = [];

const SelectsWork = {
  Default: 0,
  Break: 1
};

const SelectsLateLeave = {
  Default: 0,
  Late: 1,
  Leave: 2,
  LateLeave: 3
};

const SelectsDayCount = {
  Default: 1,
  Over: 2
};
const SelectsNightCount = {
  Default: 0,
  Over: 1
};
const SelectsMeal = {
  Default: 0,
  Eat: 1
};
const SelectsAbsence = {
  Default: 0,
  Absence: 1
};
export default {
  name: "AttendanceOfficeFixDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],

  computed: {
    Title() {
      const args = this.args;
      if (args) return "勤務実績追加・修正";
      const date = new Date(args.date);
      return args.name
        ? args.name
        : `${date.getMonth() + 1} 月 ${date.getDate()} 日`; //args.date;
    }
  },
  data() {
    return {
      date: "",
      data: {},
      users: Users,
      user: {},
      shifts: Shifts,
      shift: {},
      toggle: [],
      edit: true,
      arrayEdit: false,
      recordShifts: {},
      selectWork: SelectsWork.Default,
      SelectsWork,
      selectLateLeave: SelectsLateLeave.Default,
      SelectsLateLeave,
      selectDayCount: SelectsDayCount.Default,
      SelectsDayCount,
      selectNightCount: SelectsNightCount.Default,
      SelectsNightCount,
      selectMeal: SelectsMeal.Default,
      SelectsMeal,
      selectAbsence: SelectsAbsence.Default,
      SelectsAbsence,
      forPaid: ""
    };
  },
  methods: {
    shiftSelect() {
      console.log("shiftSelect", this.shift, this.recordShifts);
      const filter = this.recordShifts.filter(
        e => e.code === String(this.shift)
      );
      if (filter.length > 0) {
        const set = filter[0];
        console.log(set);
        if (!this.edit) {
          this.data.start = set.option1;
          this.data.end = set.option2;
          this.data.breaktime = set.option3;
        }
        if (set.refcode === "99999") {
          this.forPaid = set.code;
        } else {
          this.forPaid = "0";
        }
      }
    },
    close(result) {
      this.resolve(result);
      this.$close();
    },
    async complete() {
      console.log("complete");

      if (!this.$refs.form.validate()) return;

      const data = { ...this.data };
      const args = this.args;
      Object.keys(args).forEach(key => {
        if (this.data[key]) args[key] = data[key];
      });

      data.date = new Date(this.date).getTime();
      if (this.$isEmpty(data.recordid)) {
        data.datestr = this.date;
        data.employee = this.user;
      }
      data.shiftpatternid = this.shift ? this.shift : "";
      if (this.shift !== null && !this.$isEmpty(this.shift.code)) {
        data.shiftpatternid = this.shift.code ? this.shift.code : "";
      } else {
        data.shiftid = "";
      }
      if (data.shiftpatternid === "") {
        this.forPaid = "0";
      }
      const payLoad = new AttendanceFix(
        data.recordid,
        data.employee,
        data.affiliation,
        data.shiftid,
        data.shiftpatternid,
        data.datestr,
        data.start,
        data.end,
        data.distance,
        data.breaktime,
        data.work,
        data.over,
        data.midnight,
        data.inover,
        this.selectLateLeave,
        this.selectMeal,
        this.forPaid.length > 0 ? this.forPaid : this.selectWork,
        data.amount,
        this.selectDayCount,
        this.selectNightCount,
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        this.selectAbsence
      );

      //承認済みのチェックを掛ける
      if ((await this.checkapproval(payLoad)) == false) {
        this.$error(
          "既に上長承認が行われているか、\n 給与確定済のため、更新できません。"
        );
        this.$unloading();
        return;
      }

      this.$loading();
      try {
        let result;
        if (this.$isEmpty(data.recordid)) {
          result = await this.$post(this.Paths.attendanceFix, payLoad);
        } else {
          result = await this.$put(this.Paths.attendanceFix, payLoad);
        }

        if (result) {
          await this.$info("更新しました。", "勤務実績");
          console.log("complete end");
          this.$close(true);
        }
      } catch (e) {
        console.log("complete error", e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async getUser() {
      console.log("user");
      try {
        const result = await this.$get(this.Paths.shain1);
        if (result) {
          this.users.length = 0;
          this.users = result;
          if (this.edit) {
            this.users = result.filter(e => e.code === this.data.employee);
            this.user = this.users[0];
          }
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getShiftPattern() {
      console.log("shift-pattern");

      try {
        const result = await this.$get(this.Paths.shiftpatternPulldown);
        if (result) {
          this.shifts.length = 0;
          this.recordShifts.length = 0;
          this.recordShifts = result;
          this.recordShifts = result.filter(
            e =>
              e.refcode === this.data.affiliation ||
              e.refcode === CommonShiftRefCode
          );
          this.shifts = this.recordShifts;
          if (this.edit && this.data.affiliation) {
            if (this.data.shiftpatternid) {
              let idx = 0;
              let counter = 0;
              this.shifts.forEach(f => {
                if (f.code === this.data.shiftpatternid) idx = counter;
                counter += 1;
              });
              this.shift = this.shifts[idx];
            }
          }
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async checkapproval(payLoad) {
      const authority = this.$store.state.authority;
      console.log("checkapproval");

      /*if (authority != "1") {
        return true;
      }*/

      const param = new AttendanceFix(
        payLoad.recordid,
        payLoad.employeeId,
        payLoad.affiliation,
        payLoad.targetShiftId,
        "",
        payLoad.attendanceDate,
        payLoad.startTime,
        payLoad.endTime,
        payLoad.movingDistance,
        payLoad.breaktime,
        payLoad.work,
        payLoad.over,
        payLoad.midnight,
        payLoad.inover,
        payLoad.lateleave,
        payLoad.hadmeal,
        "",
        payLoad.amount,
        payLoad.reserveid1,
        payLoad.nightShift,
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        this.selectAbsence
      );
      console.log("getRecordByUser", param);
      const encode = encodeURI(JSON.stringify(param));
      const result2 = await this.$get(
        this.Paths.attendanceapproval,
        "query=" + encode
      );

      console.log(result2);

      if (result2.approval === "1" || result2.approval === "2") {
        return false;
      }
    },
    setRadioValues() {
      // 出勤種別
      if (this.data.workclassificationid === "1") {
        this.selectWork = this.SelectsWork.Break;
      } else {
        this.selectWork = this.SelectsWork.Default;
      }

      if (this.data.workclassificationid) {
        if (parseInt(this.data.workclassificationid) > 90000) {
          this.forPaid = this.data.workclassificationid;
        } else {
          this.forPaid = "0";
        }
      }

      // 遅刻早退
      switch (this.data.lateleave) {
        case "遅":
          this.selectLateLeave = this.SelectsLateLeave.Late;
          break;
        case "早":
          this.selectLateLeave = this.SelectsLateLeave.Leave;
          break;
        case "遅早":
          this.selectLateLeave = this.SelectsLateLeave.LateLeave;
          break;
        default:
          this.selectLateLeave = this.SelectsLateLeave.Default;
          break;
      }

      // 出勤日数
      if (this.data.reserveid1 === "2") {
        this.selectDayCount = this.SelectsDayCount.Over;
      } else {
        this.selectDayCount = this.SelectsDayCount.Default;
      }

      // 夜勤日数
      if (this.data.nightshift === "1.0") {
        this.selectNightCount = this.SelectsNightCount.Over;
      } else {
        this.selectNightCount = this.SelectsNightCount.Default;
      }

      // 食事
      if (this.data.hadmeal === "1") {
        this.selectMeal = this.SelectsMeal.Eat;
      } else {
        this.selectMeal = this.SelectsMeal.Default;
      }

      // 欠勤
      if (this.data.reserveid2 === "1") {
        this.selectAbsence = this.SelectsAbsence.Absence;
      } else {
        this.selectAbsence = this.SelectsAbsence.Default;
      }

      console.log(this.data);
    }
  },
  async created() {
    console.log("created", this.args);
    const args = this.args;
    if (!this.$isEmpty(args)) {
      if (this.$isEmpty(args["recordid"])) {
        this.edit = false;
      } else {
        this.edit = true;
      }

      if (!Array.isArray(args)) {
        this.data = { ...args };
      } else {
        this.arrayEdit = true;
        const data = {};
        args.forEach(select => {
          Object.keys(select).forEach(key => {
            if (!data[key]) data[key] = select[key];
            else if (data[key] !== select[key]) data[key] = null;
          });
        });
        this.data = data;
      }

      const date = new Date(this.data.datestr);

      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      this.date = date.toISOString().substr(0, 10);

      this.setRadioValues();

      await this.getUser();
      await this.getShiftPattern();
    }
  }
};
</script>

<style scoped>
.margin-row {
  margin-top: 0px;
}
.dialog-padding {
  padding-top: 0px;
}
</style>
